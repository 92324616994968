<template>
  <section v-if="showLogin" class="addin">
    <div v-if="!loading" class="text-center">
      <b-img
        v-if="!user.information"
        fluid
        class="mb-2 headerLogo"
        src="img/login-150px.png"
      ></b-img>
    </div>

    <p v-if="!loading" class="sefos-description text-center pl-2 pr-2">
      {{ $t("SEFOS_DESCRIPTION") }}
    </p>

    <div v-if="methods" :key="refreshKey">
      <div v-if="!loading">
        <label class="col-form-label">{{
          $t("AUTH_METHODS.SELECT_METHOD")
        }}</label>
        <b-form-select
          v-model="selectedIndex"
          :options="options"
        ></b-form-select>
      </div>

      <div v-if="currentSelectHasChilds">
        <b-form-select
          v-model="selectedChildIndex"
          :options="childs_options"
          class="mt-2"
        ></b-form-select>
      </div>

      <AuthPassword
        @authenticated="authenticated"
        @loading="setLoading"
        :loginInfo="loginInfo"
        v-if="loginInfo.auth_type == 'password'"
      ></AuthPassword>
      <AuthPasswordSms
        @authenticated="authenticated"
        @loading="setLoading"
        :loginInfo="loginInfo"
        v-if="loginInfo.auth_type == 'password-sms'"
      ></AuthPasswordSms>
      <AuthSithsNetIDEnterprise
        @authenticated="authenticated"
        @loading="setLoading"
        @startAuthentication="startAuthentication"
        :loginInfo="loginInfo"
        v-if="loginInfo.auth_method == 'net-id-enterprise' || loginInfo.auth_type == 'siths'"
      ></AuthSithsNetIDEnterprise>
      <AuthSithsNetIDAccess
        @authenticated="authenticated"
        @loading="setLoading"
        @startAuthentication="startAuthentication"
        :loginInfo="loginInfo"
        v-if="loginInfo.auth_method == 'net-id-access'"
      ></AuthSithsNetIDAccess>
      <AuthSefosCertificate
        @authenticated="authenticated"
        @loading="setLoading"
        @startAuthentication="startAuthentication"
        :loginInfo="loginInfo"
        v-if="loginInfo.auth_type == 'sefos-certificate'"
      ></AuthSefosCertificate>
      <AuthSEBankid
        @authenticated="authenticated"
        @loading="setLoading"
        @startAuthentication="startAuthentication"
        :loginInfo="loginInfo"
        v-if="loginInfo.auth_method == 'bankid-se'"
      ></AuthSEBankid>
      <AUTHSEFreja
        @authenticated="authenticated"
        @loading="setLoading"
        @startAuthentication="startAuthentication"
        :loginInfo="loginInfo"
        v-if="
          loginInfo.auth_method == 'freja' ||
          loginInfo.auth_type == 'freja-org'
        "
      ></AUTHSEFreja>

      <AuthCustom
        @authenticated="authenticated"
        @loading="setLoading"
        @startAuthentication="startAuthentication"
        :loginInfo="loginInfo"
        v-if="showCustom"
      ></AuthCustom>

       <div v-if="currentSelectHasChilds">
        <button
          :disabled="true"
          v-if="showChildrenSubmitDisabled"
          class="btn btn-fill btn-block mt-2 btn-primary"
        >
          {{ $t("NEXT") }}
        </button>
      </div>

    </div>
  </section>
</template>
<script>
export default {
  components: {
    AuthPassword: () => import("@/components/Auth/Password"),
    AuthPasswordSms: () => import("@/components/Auth/PasswordSms"),
    AuthSEBankid: () => import("@/components/Auth/SE/Bankid"),
    AUTHSEFreja: () => import("@/components/Auth/SE/Freja"),
    AuthSefosCertificate: () => import("@/components/Auth/SefosCertificate"),
    AuthSithsNetIDAccess: () => import("@/components/Auth/Siths/NetIDAccess"),
    AuthSithsNetIDEnterprise: () => import("@/components/Auth/Siths/NetIDEnterprise"),
    AuthCustom: () => import("@/components/Auth/Custom"),
  },
  data() {
    return {
      refreshIndex: 0,
      dialog: null,
      selectedIndex: 0,
      selectedChildIndex: 0,
      childs: [],
      childs_options: [],
      loginInfo: {
        auth_type: "password",
        auth_method: "",
      },
      methods: null,
      loading: false,
      addinUrl: process.env.VUE_APP_ADDIN_URL,
      actionMessage: this.$t("LOADING"),
      addinClient: "",
      options: []
    };
  },
  methods: {
    async init() {
      await this.getMethods();
      if (this.$Office.context.mailbox.diagnostics != undefined) {
        this.addinClient = this.$Office.context.mailbox.diagnostics.hostName;
      }
      if (process.env.VUE_APP_AUTH_METHOD !== "") {
        this.selected == process.env.VUE_APP_AUTH_METHOD;
      }
    },
    clearLogin(){
      this.refreshIndex = this.refreshIndex + 1;
      this.setLoading(false);
    },
    async startAuthentication(url){
      let self = this;
      let redirectUrl = this.addinUrl + "/redirect.html?url=" + url;
      this.$Office.context.ui.displayDialogAsync(redirectUrl, {height: 60, width: 40, promptBeforeOpen: false },
          function (asyncResult) {
              self.dialog = asyncResult.value;
              self.dialog.addEventHandler(self.$Office.EventType.DialogMessageReceived, self.processMessage);
              self.dialog.addEventHandler(self.$Office.EventType.DialogEventReceived, self.processDialogEvent);
          }
      );
    },
    processDialogEvent(arg) {
        switch (arg.error) {
            case 12002:
              console.log("12002");
              this.dialog.close();
              this.clearLogin();
              break;
            case 12003:
              console.log("12003");
              this.dialog.close();
              this.clearLogin();
              break;
            case 12006:
              console.log("12006");
              this.dialog.close();
              this.clearLogin();
              break;
            default:
              console.log("default");
              this.dialog.close();
              this.clearLogin();
              break;
        }
    },
    processMessage(arg) {
      var messageFromDialog = JSON.parse(arg.message);
      if (messageFromDialog.messageType === "returnedBack") {
        let self = this;
        this.$http
        .get(self.user.hostname +"/authenticate/result/" + messageFromDialog.auth_uuid)
        .then(function(result) {
          self.authenticated(result.data.token);
          self.dialog.close();
        })
        .catch(function(error) {
          self.clearLogin();
          self.dialog.close();
          if(error.toString() == "Error: fail_4_4") return;
          if(error.response != undefined)
          {
            self.$noty.error(self.$t(error.response.data));
          }
        });
      } else {
        this.clearLogin();
        this.dialog.close();
      }
    }, 
    async processMethods() {
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        let methodName = "";
        if (method.auth_type == "custom") {
          methodName = method.custom_name;
        } else {
          methodName = this.$t("AUTH_METHODS." + method.auth_type);
        }
        this.options.push({
          value: ix,
          text: methodName,
        });
        if (method.auth_type == "custom") {
          if (method.auth_method == this.loginInfo.auth_method) {
            this.selectedIndex = ix;
          }
        } else {
          if (method.auth_type == this.loginInfo.auth_type) {
            this.selectedIndex = ix;
          }
        }
      }
      if (this.selectedIndex == 0) {
        this.calculateMethods(0);
      }
    },
    async getOldMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/methods")
        .then(function(result) {
          self.methods = [];
          let oldMethods = result.data;
          for (let ix = 0; ix < oldMethods.length; ix++) {
            let oldMethod = oldMethods[ix];
            let newMethod = {
              auth_type: oldMethod.name,
              auth_method: "",
              custom_name: "",
              loa_level: oldMethod.loa_level,
              admin_org: oldMethod.admin_org,
              childs: []
            };
            if(oldMethod.childs.length != 0)
            {
              for (let ax = 0; ax < oldMethod.childs.length; ax++) {
                let oldChildMethod = oldMethod.childs[ax];
                let newChildMethod = {
                  auth_type: oldMethod.name,
                  auth_method: oldChildMethod.name,
                  custom_name: "",
                  loa_level: oldChildMethod.loa_level,
                  admin_org: oldChildMethod.admin_org,
                  childs: []
                };
                newMethod.childs.push(newChildMethod);
              }
            }
            self.methods.push(newMethod);
          }     
          self.processMethods();
        })
        .catch(function(error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          if (error.status) {
            self.$noty.error(self.$t(error.response));
          }
        });
    },
    async getMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/methods/list")
        .then(function(result) {
          self.methods = result.data;
          self.loading = false;
          self.processMethods();
        })
        .catch(function() {
          self.getOldMethods();
        });
    },
    setLoading(value) {
      this.$cookies.set("link_token", "", "1s", "", "", true, "None");
      this.$cookies.remove("link_token");
      this.$cookies.set(
        "auth_type",
        this.loginInfo.auth_type,
        Infinity,
        "",
        "",
        true,
        "None"
      );
      this.$cookies.set(
        "auth_method",
        this.loginInfo.auth_method,
        Infinity,
        "",
        "",
        true,
        "None"
      );
      this.loading = value;
    },
    authenticated: function(token) {
      this.$cookies.set("token", token, "8h", "", "", true, "None");
      this.$store.dispatch("user/fetchUser");
    },
    changedAuthenticationMethod: function() {
      this.$store.dispatch("user/setUuiMethod", this.user.uui_method);
    },
    calculateMethods(newVal) {
      this.childs = [];
      this.childs_options = [];
      let method = this.methods[newVal];
      this.loginInfo.auth_type = method.auth_type;
      if (method.auth_type == "custom") {
        this.loginInfo.auth_method = method.auth_method;
      } else {
        if (method.childs.length == 0) {
          this.loginInfo.auth_method = method.auth_method;
        } else {
          if (method.childs.length > 1) {
            this.childs_options.push({
              value: null,
              text: this.$t("AUTH_METHODS.SELECT_EXTRA_METHOD"),
            });
            this.selectedChildIndex = null;
          }
          let found = false;
          for (let ax = 0; ax < method.childs.length; ax++) {
            let childmethod = method.childs[ax];
            this.childs.push(childmethod);
            this.childs_options.push({
              value: ax,
              text: this.$t("AUTH_METHODS." + childmethod.auth_method),
            });
            if (childmethod.auth_method == this.loginInfo.auth_method) {
              this.selectedChildIndex = ax;
              found = true;
            }
          }
          if( (found == false) &&  (method.childs.length > 0))
          {
              this.loginInfo.auth_method = method.childs[0].auth_method;
              this.selectedChildIndex = 0; 
          }
        }
      }
    },
  },
  computed: {
    showCustom() {
      if(this.loginInfo.auth_type == "custom"){
        return true;
      }else{
        if(this.loginInfo.auth_type == "se-eid" && this.loginInfo.auth_method == ""){
           if(this.methods[this.selectedIndex].childs.length == 0)
           {
            return true;
           }
        }
        if(this.loginInfo.auth_type == "siths-card" && this.loginInfo.auth_method == ""){
           if(this.methods[this.selectedIndex].childs.length == 0)
           {
            return true;
           }
        }
      }
      return false;
    },
    showLogin() {
      return this.user.information == null && this.user.email != "";
    },
    currentSelectHasChilds() {
      return (
        !this.loading && this.methods[this.selectedIndex].childs.length != 0
      );
    },
    showChildrenSubmitDisabled() {
      return this.currentSelectHasChilds && (this.loginInfo.auth_method == null || this.loginInfo.auth_method == '');
    },
    refreshKey() {
      return this.refreshIndex + "_refreshLogin";
    }
  },
  watch: {
    selectedIndex: function(newVal) {
      this.calculateMethods(newVal);
    },
    selectedChildIndex: function(newVal) {
      if (newVal == null) {
        this.loginInfo.auth_method = "";
      } else {
        this.loginInfo.auth_method = this.childs[newVal].auth_method;
      }
    },
    "loginInfo.auth_method": function(newVal) {
      if (newVal == "null") {
        newVal = "";
      }
      this.$cookies.set("auth_method", newVal, Infinity, "", "", true, "None");
    },
    "loginInfo.auth_type": function(newVal) {
      this.$cookies.set("auth_type", newVal, Infinity, "", "", true, "None");
    },
    "loginInfo.email": function(newVal) {
      this.$cookies.set("email", newVal, Infinity, "", "", true, "None");
    },
  },
  mounted() {
    if (this.$cookies.isKey("auth_method")) {
      this.loginInfo.auth_method = this.$cookies.get("auth_method");
    }
    if (this.$cookies.isKey("auth_type")) {
      this.loginInfo.auth_type = this.$cookies.get("auth_type");
    }
    this.init();
  },
};
</script>
<style></style>
